// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-about-jsx": () => import("./../../../src/pages/About/About.jsx" /* webpackChunkName: "component---src-pages-about-about-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-sections-introduction-jsx": () => import("./../../../src/pages/About/Sections/Introduction.jsx" /* webpackChunkName: "component---src-pages-about-sections-introduction-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-us-contact-us-jsx": () => import("./../../../src/pages/ContactUs/ContactUs.jsx" /* webpackChunkName: "component---src-pages-contact-us-contact-us-jsx" */),
  "component---src-pages-contact-us-sections-contact-form-jsx": () => import("./../../../src/pages/ContactUs/Sections/ContactForm.jsx" /* webpackChunkName: "component---src-pages-contact-us-sections-contact-form-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-news-jsx": () => import("./../../../src/pages/News/News.jsx" /* webpackChunkName: "component---src-pages-news-news-jsx" */),
  "component---src-pages-news-sections-news-card-jsx": () => import("./../../../src/pages/News/Sections/NewsCard.jsx" /* webpackChunkName: "component---src-pages-news-sections-news-card-jsx" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-success-jsx": () => import("./../../../src/pages/Success/Success.jsx" /* webpackChunkName: "component---src-pages-success-success-jsx" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-pages-welcome-welcome-jsx": () => import("./../../../src/pages/Welcome/Welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-welcome-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../../../src/templates/newsArticle.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */)
}

